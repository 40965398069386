import { defineComponent as _defineComponent } from 'vue'
import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center relative" }
const _hoisted_2 = ["placeholder", "title"]

import Icon from '../elements/Icon.vue';

	import { ref } from 'vue';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'SearchRoomsInput',
  emits: ['update-search-term'],
  setup(__props, { emit: __emit }) {

	// Components
	const emit = __emit;
	const inputValue = ref('');

	function updateSearchTerm() {
		emit('update-search-term', inputValue.value);
	}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      class: "w-full min-w-48 md:pr-8 py-2 text-hub-text bg-hub-background-3 placeholder:text-gray border-none rounded-md focus:ring-0",
      type: "search",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((inputValue).value = $event)),
      placeholder: _ctx.$t('others.search'),
      title: _ctx.$t('others.search'),
      "aria-describedby": "$t('others.search_room')",
      onInput: _cache[1] || (_cache[1] = ($event: any) => (updateSearchTerm()))
    }, null, 40, _hoisted_2), [
      [_vModelText, inputValue.value]
    ]),
    _createVNode(Icon, {
      class: "-ml-6 hover:cursor-pointer",
      type: "search",
      size: "sm"
    })
  ]))
}
}

})