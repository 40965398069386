import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["src"]

import { useUserColor } from '@/composables/useUserColor';
	import RoomMember from '@/model/rooms/RoomMember';
	import { CurrentUser, useUser } from '@/store/user';
	import { computed } from 'vue';

	//Components
	import Icon from '../elements/Icon.vue';

	type Props = {
		user: RoomMember | CurrentUser | null | undefined;
		overrideAvatarUrl?: string | undefined;
	};

	const backgroundColor = 'bg-hub-background-4';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'Avatar',
  props: {
    user: {},
    overrideAvatarUrl: {}
  },
  setup(__props: any) {

	const { color, bgColor } = useUserColor();

	const currentUser = useUser();

	const props = __props;

	const imageUrl = computed(getImageUrl);
	const avatarColor = computed(getAvatarColor);

	function getImageUrl(): string | undefined | null {
		if (!props.user) {
			return undefined;
		} else if (props.overrideAvatarUrl !== undefined) {
			return props.overrideAvatarUrl;
		} else if (userIsCurrentUser()) {
			return currentUser.avatarUrl;
		} else {
			return props.user.avatarUrl;
		}
	}

	function getAvatarColor(): string {
		if (!props.user?.userId) return backgroundColor;

		if (imageUrl.value) {
			return backgroundColor;
		} else {
			return bgColor(color(props.user.userId));
		}
	}

	function userIsCurrentUser(): boolean {
		return props.user?.userId === currentUser.userId;
	}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["rounded-full w-12 h-12 shrink-0 aspect-square flex items-center justify-center overflow-hidden", avatarColor.value])
  }, [
    (imageUrl.value)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          "data-testid": "avatar",
          src: imageUrl.value,
          class: "w-full h-full"
        }, null, 8, _hoisted_1))
      : (_openBlock(), _createBlock(Icon, {
          key: 1,
          size: "lg",
          type: "person"
        }))
  ], 2))
}
}

})