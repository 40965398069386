import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, withModifiers as _withModifiers, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["alt", "src"]
const _hoisted_2 = ["alt", "src"]

import Popover from '../ui/Popover.vue';

	import { useMatrixFiles } from '@/composables/useMatrixFiles';
	import { TImageMessageEventContent } from '@/model/events/TMessageEvent';
	import { ref } from 'vue';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'MessageImage',
  props: {
    message: {}
  },
  setup(__props: any) {

	// Components
	const { formUrlfromMxc } = useMatrixFiles();

	const showFullImage = ref(false);

	const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("img", _mergeProps(_ctx.$attrs, {
      alt: _ctx.message.body,
      src: _unref(formUrlfromMxc)(_ctx.message.url),
      class: "object-contain cursor-pointer",
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (showFullImage.value = true), ["stop"]))
    }), null, 16, _hoisted_1),
    (showFullImage.value)
      ? (_openBlock(), _createBlock(Popover, {
          key: 0,
          onClose: _cache[1] || (_cache[1] = ($event: any) => (showFullImage.value = false)),
          class: "w-[100vw] h-[100vh] top-0 left-0 fixed z-50 flex",
          "show-closing-cross": true
        }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              alt: _ctx.message.body,
              src: _unref(formUrlfromMxc)(_ctx.message.url),
              class: "h-4/5 w-4/5 m-auto object-contain"
            }, null, 8, _hoisted_2)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})