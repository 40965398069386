import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "hidden md:flex items-center gap-4" }
const _hoisted_2 = { class: "text-xxs uppercase font-bold" }
const _hoisted_3 = { class: "flex h-full justify-between relative gap-x-2" }
const _hoisted_4 = {
  key: 0,
  class: "flex shrink-0 gap-x-1 md:gap-x-4 items-center w-[75%] md:w-[60%] overflow-hidden"
}
const _hoisted_5 = { class: "flex flex-col" }

import HeaderFooter from '@/components/ui/HeaderFooter.vue';
	import Icon from '@/components/elements/Icon.vue';
	import H1 from '@/components/elements/H1.vue';
	import TruncatedText from '@/components/elements/TruncatedText.vue';
	import RoomName from '@/components/rooms/RoomName.vue';
	import RoomTopic from '@/components/rooms/RoomTopic.vue';
	import SearchInput from '@/components/forms/SearchInput.vue';
	import RoomTimeline from '@/components/rooms/RoomTimeline.vue';
	import MessageInput from '@/components/forms/MessageInput.vue';
	import PrivateRoomName from '@/components/rooms/PrivateRoomName.vue';

	import { usePubHubs } from '@/core/pubhubsStore';
	import { LOGGER } from '@/foundation/Logger';
	import { SMI } from '@/dev/StatusMessage';
	import { TSearchParameters } from '@/model/search/TSearch';
	import { useHubSettings } from '@/store/hub-settings';
	import { PluginProperties, usePlugins } from '@/store/plugins';
	import { useRooms } from '@/store/rooms';
	import { useUser } from '@/store/user';
	import { computed, onMounted, ref, watch } from 'vue';
	import { useRoute, useRouter } from 'vue-router';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'Room',
  props: {
		id: { type: String, required: true },
	},
  setup(__props) {

	// Components
	const route = useRoute();
	const rooms = useRooms();
	const router = useRouter();
	const plugins = usePlugins();
	const plugin = ref(false as boolean | PluginProperties);
	const hubSettings = useHubSettings();
	const user = useUser();
	const pubhubs = usePubHubs();

	//Passed by the router
	const props = __props;

	const searchParameters = ref<TSearchParameters>({ roomId: props.id, term: '' });
	const scrollToEventId = ref<string>('');

	const room = computed(() => {
		let r = rooms.rooms[props.id];
		if (!r) {
			// I want the side effect that should be avoided according to the lint rule.
			// eslint-disable-next-line
			router.push({ name: 'error-page', query: { errorKey: 'errors.cant_find_room' } });
		}
		return r;
	});

	onMounted(() => {
		update();
		LOGGER.log(SMI.ROOM, `Room mounted `);
	});

	watch(route, () => {
		update();
	});

	async function update() {
		hubSettings.hideBar();
		rooms.changeRoom(props.id);

		const userIsMemberOfRoom = await pubhubs.isUserRoomMember(user.user.userId, props.id);
		if (!userIsMemberOfRoom) {
			// if not a member: try to join, otherwise go to the hubpage
			pubhubs.joinRoom(props.id).catch(() => router.push({ name: 'hubpage' }));
		}

		if (!rooms.currentRoom) return;
		searchParameters.value.roomId = rooms.currentRoom.roomId;
		plugin.value = plugins.hasRoomPlugin(rooms.currentRoom);
	}

	async function onScrollToEventId(ev: any) {
		scrollToEventId.value = ev.eventId;
	}

return (_ctx: any,_cache: any) => {
  return (_unref(rooms).currentRoomExists)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        (plugin.value === false)
          ? (_openBlock(), _createBlock(HeaderFooter, {
              key: 0,
              headerSize: 'sm',
              headerMobilePadding: true
            }, {
              header: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('rooms.room')), 1),
                  _cache[1] || (_cache[1] = _createElementVNode("hr", { class: "grow" }, null, -1))
                ]),
                _createElementVNode("div", _hoisted_3, [
                  (_unref(rooms).currentRoom)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createVNode(Icon, {
                          type: _unref(rooms).currentRoom.isSecuredRoom() ? 'shield' : 'speech_bubbles',
                          size: "lg"
                        }, null, 8, ["type"]),
                        _createElementVNode("div", _hoisted_5, [
                          _createVNode(H1, { class: "flex" }, {
                            default: _withCtx(() => [
                              _createVNode(TruncatedText, null, {
                                default: _withCtx(() => [
                                  (_unref(rooms).currentRoom.isPrivateRoom())
                                    ? (_openBlock(), _createBlock(PrivateRoomName, {
                                        key: 0,
                                        members: _unref(rooms).currentRoom.getOtherJoinedAndInvitedMembers()
                                      }, null, 8, ["members"]))
                                    : (_openBlock(), _createBlock(RoomName, {
                                        key: 1,
                                        room: _unref(rooms).currentRoom
                                      }, null, 8, ["room"]))
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(TruncatedText, { class: "hidden md:inline" }, {
                            default: _withCtx(() => [
                              _createVNode(RoomTopic, {
                                room: _unref(rooms).currentRoom
                              }, null, 8, ["room"])
                            ]),
                            _: 1
                          })
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  _createVNode(SearchInput, {
                    "search-parameters": searchParameters.value,
                    onScrollToEventId: onScrollToEventId,
                    room: _unref(rooms).currentRoom
                  }, null, 8, ["search-parameters", "room"])
                ])
              ]),
              footer: _withCtx(() => [
                (room.value)
                  ? (_openBlock(), _createBlock(MessageInput, {
                      key: 0,
                      room: room.value
                    }, null, 8, ["room"]))
                  : _createCommentVNode("", true)
              ]),
              default: _withCtx(() => [
                (room.value)
                  ? (_openBlock(), _createBlock(RoomTimeline, {
                      key: 0,
                      room: room.value,
                      "scroll-to-event-id": scrollToEventId.value,
                      onScrolledToEventId: _cache[0] || (_cache[0] = ($event: any) => (scrollToEventId.value = ''))
                    }, null, 8, ["room", "scroll-to-event-id"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (plugin.value !== false && plugin.value !== true)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(plugin.value.component), { key: 1 }))
          : _createCommentVNode("", true)
      ], 64))
    : _createCommentVNode("", true)
}
}

})