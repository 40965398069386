import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import Dialog from '../ui/Dialog.vue';
	import DisclosureYivi from './DisclosureYivi.vue';

	import { ref } from 'vue';
	import { DialogButton, DialogButtonAction, DialogOk, DialogCancel } from '@/store/dialog';
	import { useRooms } from '@/store/store';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'Disclosure',
  emits: ['close'],
  setup(__props, { emit: __emit }) {

	// Components
	const rooms = useRooms();
	const emit = __emit;
	const buttonsContinueIgnore: Array<DialogButton> = [new DialogButton('continue', 'blue', DialogOk), new DialogButton('ignore', 'red', DialogCancel)];

	const showDisclosureDialog = ref(false);
	const showDisclosureYivi = ref(false);

	// Present each 'askDisclosureMessage' to the user only once.
	// On receiving a new 'askDisclosureMessage' in the room state, start
	// the Yivi flow.
	rooms.$subscribe(async (mutation, state) => {
		if (!showDisclosureYivi.value && state.newAskDisclosureMessage) {
			console.debug(`Disclosure: start (ask={${state.askDisclosureMessage?.userId},...})`);
			showDisclosureDialog.value = true;
		}
	});

	// On close, reset 'newAskDisclosureMessage' so it's possible to receive another request later.
	function closeDialog(returnValue: DialogButtonAction) {
		showDisclosureDialog.value = false;
		if (returnValue === 1) {
			showDisclosureYivi.value = true;
		}
		emit('close');
	}

	function closeYivi() {
		showDisclosureYivi.value = false;
		rooms.newAskDisclosureMessage = false;
	}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (showDisclosureDialog.value)
      ? (_openBlock(), _createBlock(Dialog, {
          key: 0,
          title: _ctx.$t('admin.disclosure_dialog_title'),
          buttons: buttonsContinueIgnore,
          width: "w-3/6",
          onClose: _cache[0] || (_cache[0] = ($event: any) => (closeDialog($event)))
        }, {
          default: _withCtx(() => [
            _cache[1] || (_cache[1] = _createElementVNode("p", null, "The moderator is asking you to disclose some information.", -1)),
            _cache[2] || (_cache[2] = _createElementVNode("p", null, "Please use Yivi to reveal the following identity attribute(s).", -1)),
            _createElementVNode("ul", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(rooms).askDisclosureMessage!.attributes, (item, index) => {
                return (_openBlock(), _createElementBlock("li", { key: index }, _toDisplayString(item.yivi), 1))
              }), 128))
            ]),
            _cache[3] || (_cache[3] = _createElementVNode("p", null, "To proceed, click 'Continue' and follow the Yivi instructions.", -1)),
            _cache[4] || (_cache[4] = _createElementVNode("p", null, "TODO: Alternatively, please discuss this with the moderator...", -1))
          ]),
          _: 1
        }, 8, ["title"]))
      : _createCommentVNode("", true),
    (showDisclosureYivi.value)
      ? (_openBlock(), _createBlock(DisclosureYivi, {
          key: 1,
          ask: _unref(rooms).askDisclosureMessage,
          onClose: closeYivi
        }, null, 8, ["ask"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})