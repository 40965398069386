import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full" }

import Tabs from '../ui/Tabs.vue';
	import FormObjectInputContent from './FormObjectInputContent.vue';

	import { ref } from 'vue';
	import { FormObjectInputTemplate } from '@/composables/useFormInputEvents';

	// const emit = defineEmits(['input']);

	
export default /*@__PURE__*/_defineComponent({
  __name: 'FormObjectInput',
  props: {
		template: {
			type: Array<FormObjectInputTemplate>,
			required: true,
		},
		modelValue: {
			type: Array<any>,
			required: true,
		},
		canAdd: {
			type: Boolean,
			required: false,
			default: true,
		},
		canRemove: {
			type: Boolean,
			required: false,
			default: true,
		},
	},
  setup(__props) {

	// Components
	const props = __props;

	const value = ref(props.modelValue);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(Tabs, null, {
      default: _withCtx(() => [
        _createVNode(FormObjectInputContent, {
          template: __props.template,
          canAdd: props.canAdd,
          canRemove: props.canRemove,
          modelValue: value.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event))
        }, null, 8, ["template", "canAdd", "canRemove", "modelValue"])
      ]),
      _: 1
    })
  ]))
}
}

})