import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mt-2 rounded-md bg-hub-background-3 p-2 flex"
}
const _hoisted_2 = ["href"]

import Icon from '../elements/Icon.vue';

	import { TFileMessageEventContent } from '@/model/events/TMessageEvent';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'MessageFile',
  props: {
    message: {}
  },
  setup(__props: any) {

	// Components
	

return (_ctx: any,_cache: any) => {
  return (_ctx.url)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(Icon, {
          type: "paperclip",
          class: "mr-2"
        }),
        _createElementVNode("a", {
          class: "text-blue",
          target: "_blank",
          href: _ctx.url
        }, _toDisplayString(_ctx.message.filename), 9, _hoisted_2)
      ]))
    : _createCommentVNode("", true)
}
}

})