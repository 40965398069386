import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import HeaderFooter from '@/components/ui/HeaderFooter.vue';
	import H1 from '@/components/elements/H1.vue';
	import H2 from '@/components/elements/H2.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'PluginToolExample',
  setup(__props) {

	// Components
	
return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(HeaderFooter, { class: "pl-3" }, {
    header: _withCtx(() => [
      _createVNode(H1, { class: "m-0 text-blue font-bold" }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("Tool")
        ])),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(H2, null, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode("Example Plugin")
        ])),
        _: 1
      }),
      _cache[2] || (_cache[2] = _createElementVNode("p", null, "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat.", -1))
    ]),
    _: 1
  }))
}
}

})