import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "absolute top-4 flex justify-center w-full z-50"
}
const _hoisted_2 = { class: "px-2.5 bg-gray-middle text-white-middle dark:bg-gray-lighter dark:text-gray-middle text-center rounded-full" }

import { useTimeFormat } from '@/composables/useTimeFormat';
	import { useRooms } from '@/store/rooms';
	
export default /*@__PURE__*/_defineComponent({
  __name: 'DateDisplayer',
  props: {
		eventTimeStamp: {
			type: Number,
			required: true,
		},
		scrollStatus: {
			type: Boolean,
			required: true,
		},
	},
  setup(__props) {

	const { formattedTimeInformation } = useTimeFormat();
	const rooms = useRooms();

	const props = __props;

	function displayDate(): string {
		if (!rooms.currentRoom?.hasMessages()) return '';
		return formattedTimeInformation(props.eventTimeStamp);
	}

return (_ctx: any,_cache: any) => {
  return (props.scrollStatus && displayDate() !== '')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(displayDate()), 1)
      ]))
    : _createCommentVNode("", true)
}
}

})